<template>
    <quill-editor :content="content"
        ref="myQuillEditor"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
        @change="onEditorChange($event)">
    </quill-editor>  
</template>

<script>
import { quillEditor } from 'vue-quill-editor';

export default {
    Name: "MctEditorTexto",
    props: ["valor","campo"],
    components: {
        quillEditor
    },
    data () {
        return {
            editorOption: {
               placeholder: 'Digite seu texto aqui.'
            },
            content: ''
        }
    },  
    methods : {
        onEditorBlur (e) {

        },
        onEditorFocus (e) {

        },
        onEditorReady (e){

        },
        onEditorChange({ quill, html, text }) {
            /*console.log('editor change!', quill, html, text)*/
            this.valor[this.campo] = html
        }        
    } 
}

</script>

<style lang="css">
/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
